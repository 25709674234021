@import "../../_globals/variables";

.carousel {

	&__overlay {
		&_title,
		&_subtitle {
			color: white;
			padding: 0 $padding;
			text-align: center;
		}

		&_title {
			font-size: clamp(40px, 4vw, 70px);
			line-height: 1.1;
		}

		&_subtitle {
			font-size: clamp(18px, 1.25vw, 22px);
			font-weight: 500;
			line-height: 1.3;
			margin: 10px 0;
		}
	}	
}