@import "../../_globals/variables";

.home {
	&__about {
		background-color: #f8fafe;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 80px $padding;

		@media only screen and (max-width: 767px) {
			display: block;
		}

		@media only screen and (min-width: 768px) and (max-width: 1024px) {
			display: block;
		}
		
		&_left {
			width: 50%;

			@media only screen and (max-width: 767px) {
				width: 100%;
			}

			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				width: 100%;
			}

			h2 {
				margin: 10px 0 20px;
			}

			p {
				margin-bottom: 15px;
			}

			&_bottom {
				padding: 30px 0;

				&_box {
					position: relative;
					background-color: $accent;
					border-radius: $border-radius;
					color: #fff;
					font-family: $heading-font;
					font-size: clamp(18px, 1.25vw, 22px);
					line-height: 1.3;
					padding: 60px 30px;

					span {
						color: $secondary;
					}

					&::before {
						position: absolute;
						content: '';
						left: 35px;
						top: 30px;
						width: 10px;
						height: 10px;
						background-color: $secondary;
					}

					&::after {
						position: absolute;
						content: '';
						right: 45px;
						bottom: 40px;
						width: 10px;
						height: 10px;
						background-color: $white;
					}
				}

				&_right {
					padding-left: 50px;
					width: 60%;

					@media only screen and (max-width: 767px) {
						width: 100%;
						padding-left: 0;
						margin-top: 30px;
					}

					h3 {
						font-size: clamp(18px, 1.25vw, 22px);
						line-height: 1;
						margin-bottom: 5px;
					}

					p {
						font-weight: 500;
					}

					img {
						width: 40px;
						height: 40px;
						object-fit: contain;
					}

					&_btn {
						margin: 15px 0;

						@media only screen and (max-width: 767px) {
							display: flex;
							align-items: center;
							justify-content: center;
						}

						@media only screen and (min-width: 768px) and (max-width: 1024px) {
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
				}
			}
		}

		&_right {
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: center;

			@media only screen and (max-width: 767px) {
				width: 100%;
			}

			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				width: 100%;
			}

			img {
				height: auto;
				width: 500px;
				object-fit: contain;

				@media only screen and (max-width: 767px) {
					display: block;
					width: 80vw;
				}
			}
		}
	}

	&__posts {
		padding: 50px $padding;

		&_top {
			text-align: center;
			max-width: 55%;
			margin: 0 auto;

			@media only screen and (max-width: 767px) {
				max-width: 100%;
			}

			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				max-width: 100%;
			}

			h2 {
				margin: 10px 0 20px;
				line-height: 1;
			}
		}

		&_cards {
			padding: 50px;
			display: grid;
			grid-template-columns: repeat(3, minmax(0, 1fr));
			grid-template-rows: 1fr;
			gap: 50px;
			grid-template-areas:
				". . .";
	
			@media only screen and (max-width: 767px) {
				grid-template-columns: repeat(1, minmax(0, 1fr));
				gap: 50px 0;
				grid-template-areas:
					".";
				padding: 50px 0;
			}
	
	
			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				grid-template-columns: repeat(2, minmax(0, 1fr));
				gap: 50px;
				grid-template-areas:
					". .";
				padding: 50px 0;
			}
		}
	}

	&__stats {
		padding: 50px $padding;
		background-color: $heading;
		color: $white;

		h3 {
			color: $white;
		}

		@media only screen and (max-width: 767px) {
			padding: 50px 0;
		}

		&_cards {
			display: grid;
			grid-template-columns: repeat(4, minmax(0, 1fr));
			grid-template-rows: 1fr;
			gap: 50px;
			grid-template-areas:
				". . . .";
	
			@media only screen and (max-width: 767px) {
				grid-template-columns: repeat(2, minmax(0, 1fr));
				gap: 50px 0;
				grid-template-areas:
					". .";
			}
	
	
			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				grid-template-columns: repeat(4, minmax(0, 1fr));
				gap: 50px;
				grid-template-areas:
					". . . .";
			}
		}
	}

	&__values {
		padding: 80px $padding 50px;

		&_top {
			display: flex;
			align-items: center;
			justify-content: space-between;
	
			@media only screen and (max-width: 767px) {
				display: block;
			}

			&_left {
				width: 45%;

				@media only screen and (max-width: 767px) {
					width: 100%;
				}

				h2 {
					@media only screen and (max-width: 767px) {
						margin: 10px 0;
					}
				}
			}

			&_right {
				width: 50%;

				@media only screen and (max-width: 767px) {
					width: 100%;
				}

				@media only screen and (min-width: 768px) and (max-width: 1024px) {
					width: 55%;
				}
			}
		}

		&_cards {
			padding: 80px 0;
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			grid-template-rows: 1fr;
			gap: 50px;
			grid-template-areas:
				". .";
	
			@media only screen and (max-width: 767px) {
				grid-template-columns: repeat(1, minmax(0, 1fr));
				gap: 50px 0;
				grid-template-areas:
					".";
				padding: 50px 0;
			}
	
	
			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				grid-template-columns: repeat(2, minmax(0, 1fr));
				gap: 50px;
				grid-template-areas:
					". .";
				padding: 50px 0;
			}
		}
	}
}