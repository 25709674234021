@import "../../_globals/variables";

.hero {
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	height: 35vh;
	margin-top: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 100px $padding;

	/* CSS for other than iOS devices */ 
	@supports not (-webkit-touch-callout: none) {
		background-attachment: fixed !important;
	}

	@media only screen and (max-width: 767px) {
		height: 40vh;
		padding: 50px $padding;
	}

	@media only screen and (min-width: 768px) and (max-width: 1024px) {
		height: 25vh;
		padding: 50px $padding;
	}

	h1 {
		color: $white;
		margin-bottom: 20px;
		text-align: center;
		text-transform: capitalize;
	}
}