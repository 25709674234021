@import "../../_globals/variables";

.spinner {
  margin: auto;
  position: relative;
  border-top: 0.5rem solid rgba($white, 0.2);
  border-right: 0.5rem solid rgba($white, 0.2);
  border-bottom: 0.5rem solid rgba($white, 0.2);
  border-left: 0.5rem solid $white;
  transform: translateZ(0);
  animation: loading 0.6s infinite linear;

  &,
  &:after {
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}