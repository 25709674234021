@import "../../_globals/variables";

.btn {
	&__primary {
		text-align: center;
		color: $white;
		font-size: clamp(13px, 1vw, 18px);
		font-weight: 500;
		padding: 10px 20px;
		background: linear-gradient(to right, $secondary 50%, $primary 50%);
		background-size: 200% 100%;
		background-color: $primary;
		background-position: right bottom;
		border-radius: 4px;
		transition: all .3s ease-out;
		text-decoration: none;
		text-transform: uppercase;

		&:hover {
			color: $white;
			background-color: $secondary;
			background-position: left bottom;
		}
	}

	&__secondary {
		text-align: center;
		color: $white;
		font-size: clamp(13px, 1vw, 18px);
		font-weight: 500;
		padding: 5px 15px;
		background: linear-gradient(to right, $white 50%, $secondary 50%);
		background-size: 200% 100%;
		background-color: transparent;
		background-position: right bottom;
		border-radius: 4px;
		transition: all .3s ease-out;
		text-decoration: none;
		text-transform: uppercase;
		border: 1px $white solid;

		&:hover {
			color: $secondary;
			background-color: $white;
			background-position: left bottom;
			border: 1px $white solid;
		}
	}

	&__arrow {
		color: $text;
		font-size: clamp(14px, 1vw, 18px);
		font-weight: 500;
	}
}