@import "../../../_globals/variables";

.photo {
	width: 18vw !important;
	height: 200px !important;
	object-fit: cover;
	margin: 15px;
	border-radius: $border-radius;

	@media only screen and (max-width: 767px) {
		width: 75vw !important;
	}

	@media only screen and (min-width: 768px) and (max-width: 1024px) {
		width: 37vw !important;
	}
}