@import "../../_globals/variables";

.about {
	&__history {
		padding: 50px $padding;
		
		&_left {
			h2 {
				margin: 10px 0 20px;
			}

			p {
				margin-bottom: 15px;
			}

			&_bottom {
				display: flex;
				align-items: center;
				padding: 30px 0;

				@media only screen and (max-width: 767px) {
					display: block;
				}

				&_box {
					position: relative;
					background-color: $accent;
					border-radius: $border-radius;
					color: #fff;
					font-family: $heading-font;
					font-size: clamp(23px, 1.8vw, 26px);
					font-weight: 600;
					line-height: 1.3;
					padding: 60px 30px;
					width: 20%;

					@media only screen and (max-width: 767px) {
						width: 50%;
					}

					@media only screen and (min-width: 768px) and (max-width: 1024px) {
						width: 30%;
					}

					span {
						color: $secondary;
					}

					&::before {
						position: absolute;
						content: '';
						left: 35px;
						top: 30px;
						width: 10px;
						height: 10px;
						background-color: $secondary;
					}

					&::after {
						position: absolute;
						content: '';
						right: 45px;
						bottom: 40px;
						width: 10px;
						height: 10px;
						background-color: $white;
					}
				}

				&_cards {
					margin-left: 50px;
					display: grid;
					grid-template-columns: repeat(4, minmax(0, 1fr));
					grid-template-rows: 1fr;
					gap: 50px;
					grid-template-areas:
						". . . .";
			
					@media only screen and (max-width: 767px) {
						margin-left: 0;
						margin-top: 50px;
						grid-template-columns: repeat(2, minmax(0, 1fr));
						gap: 50px 0;
						grid-template-areas:
							". .";
					}
			
			
					@media only screen and (min-width: 768px) and (max-width: 1024px) {
						grid-template-columns: repeat(2, minmax(0, 1fr));
						gap: 20px 50px;
						grid-template-areas:
							". .";
					}
				}
			}
		}
	}

	&__mission {
		background-color: $light-background;
		padding: 80px $padding 50px;

		&_top {
			display: flex;
			align-items: center;
			justify-content: space-between;
	
			@media only screen and (max-width: 767px) {
				display: block;
			}

			&_left {
				width: 45%;

				@media only screen and (max-width: 767px) {
					width: 100%;
				}

				h2 {
					@media only screen and (max-width: 767px) {
						margin: 10px 0;
					}
				}
			}

			&_right {
				width: 50%;

				@media only screen and (max-width: 767px) {
					width: 100%;
				}

				@media only screen and (min-width: 768px) and (max-width: 1024px) {
					width: 55%;
				}
			}
		}

		&_cards {
			padding: 80px 0;
			display: grid;
			grid-template-columns: repeat(3, minmax(0, 1fr));
			grid-template-rows: 1fr;
			gap: 50px;
			grid-template-areas:
				". .";
	
			@media only screen and (max-width: 767px) {
				grid-template-columns: repeat(1, minmax(0, 1fr));
				gap: 50px 0;
				grid-template-areas:
					".";
				padding: 50px 0;
			}
	
	
			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				grid-template-columns: repeat(1, minmax(0, 1fr));
				gap: 50px 0;
				grid-template-areas:
					".";
				padding: 50px 0;
			}

			&_component {
				margin-bottom: 30px;
				position: relative;
			
				&__inner {
					position: relative;
					overflow: hidden;
					padding: 50px;
					background-color: $white;
					border-radius: $border-radius;
			
					&::before {
						position: absolute;
						content: '';
						left: -75px;
						top: -60px;
						width: 120px;
						height: 120px;
						border-radius: 50%;
						background-color: $secondary;
						transition: all 300ms ease;
					}
			
					&_top {
						display: flex;
						align-items: center;
						justify-content: space-between;
			
						img {
							width: 50px;
							height: 50px;
							object-fit: contain;
				
							@media only screen and (max-width: 767px) {
								
							}
				
							@media only screen and (min-width: 768px) and (max-width: 1024px) {
							
							}
						}
					}
			
					h3 {
						margin: 10px 0;
						font-size: clamp(18px, 1.3vw, 22px);
					}
			
					h1 {
						color: $background;
					}
					
					p {
						font-size: clamp(14px, 1vw, 18px);
						margin-bottom: 15px;
					}
				}
			}
		}
	}

	&__team {
		padding: 50px $padding;

		&_top {
			text-align: center;
			max-width: 50%;
			margin: 0 auto;

			@media only screen and (max-width: 767px) {
				max-width: 100%;
			}

			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				max-width: 100%;
			}

			h2 {
				margin: 10px 0 20px;
			}
		}

		&_cards {
			padding: 50px 0;
			display: grid;
			grid-template-columns: repeat(3, minmax(0, 1fr));
			grid-template-rows: 1fr;
			gap: 0 50px;
			grid-template-areas:
				". . .";
	
			@media only screen and (max-width: 767px) {
				grid-template-columns: repeat(1, minmax(0, 1fr));
				gap: 0;
				grid-template-areas:
					".";
				padding: 30px 0;
			}
	
	
			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				grid-template-columns: repeat(2, minmax(0, 1fr));
				gap: 0;
				grid-template-areas:
					". .";
				padding: 30px 0;
			}
		}
	}
}