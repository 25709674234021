@import "../../_globals/variables";

.footer {
	background-color: $heading;
	color: $white;

	&__top {
		padding: 60px $padding 30px;
		display: grid;
		grid-template-columns: 1.5fr 1fr 1fr 1.2fr; 
		grid-template-rows: 1fr;
		gap: 50px;
		grid-template-areas:
			". . . .";

		@media only screen and (max-width: 767px) {
			grid-template-columns: repeat(1, minmax(0, 1fr));
			gap: 50px 0;
			grid-template-areas:
				".";
		}


		@media only screen and (min-width: 768px) and (max-width: 1024px) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
			grid-template-rows: 1fr;
			gap: 50px 0;
			grid-template-areas:
				". .";
		}

		h4 {
			color: $white;
			font-size: clamp(16px, 1.2vw, 18px);
			margin-bottom: 15px;
		}

		p {
			font-size: clamp(15px, 1vw, 18px);
		}

		a {
			color: $white;
			font-size: clamp(15px, 1vw, 18px);

			&:hover {
				color: $link;
			}
		}

		&_contact {
			line-height: 2;
		}

		&_socials {
            &_single {
                display: flex;
                align-items: center;
                margin-bottom: 15px;

                img {
                    width: 30px;
                    height: 30px;
                    object-fit: contain;
                    margin: 0 10px 0 0;
                }

                a {
                    color: $white;
                    text-decoration: none;
					margin-top: -8px;
					margin-left: 5px;
                }
            }
        }

		&_links {
			margin-bottom: 5px;
		}
	}

	&__copyright {
		color: $white;
		border-top: .5px $white solid;
		font-size: clamp(15px, 1vw, 18px);
		padding: 15px $padding;
		display: flex;
		justify-content: center;
		align-items: center;

		@media only screen and (max-width: 767px) {
			display: block;
			text-align: center;
		}
		
		a {
			color: $white;
			font-weight: 500;
			text-transform: uppercase;

			&:hover {
				color: $link;
			}
		}
	}
}