@import "../../_globals/variables";

.team {
	margin-bottom: 30px;

	&__inner {
		padding: 15px 35px 50px;

		img {
			width: 200px;
			height: 200px;
			object-fit: cover;
			border-radius: 50%;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}

		h3 {
			font-size: clamp(18px, 1.2vw, 20px);
			margin: 20px 0 10px;
			text-align: center;
		}
		
		p {
			font-size: clamp(15px, 1vw, 18px);
			text-align: center;
			margin-bottom: 5px;
		}

		&_social {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 30px;
				height: 30px;
				object-fit: contain;
				margin-right: 10px;
			}
		}
	}

	&__modal {

		&_btn {
			text-align: center;

			button {
				color: $heading;
				font-size: clamp(14px, .9vw, 18px);
				font-family: $body-font;
				font-weight: 500;
				border: none;
				background-color: transparent;
				cursor: pointer;
			}
		}
		
		&_wrapper {
			display: flex;
			justify-content: space-between;
			padding: 30px 0 50px;

			@media only screen and (max-width: 767px) {
				flex-direction: column;
				padding: 10px 0 20px;
			}

			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				flex-direction: column;
				padding: 10px 0 20px;
			}

			&_left {
				width: 29%;
				text-align: center;

				@media only screen and (max-width: 767px) {
					width: 100%;
					margin-bottom: 20px;
				} 

				@media only screen and (min-width: 768px) and (max-width: 1024px) {
					width: 100%;
					margin-bottom: 20px;
				}

				img {
					width:250px;
					height: 250px;
					object-fit: contain;
					border-radius: 50%;

					@media only screen and (max-width: 767px) {
						width: 150px;
						height: 150px;
					}

					@media only screen and (min-width: 768px) and (max-width: 1024px) {
						width: 150px;
						height: 150px;
					}
				}

				h3 {
					font-size: clamp(18px, 1.25vw, 22px);
					margin: 15px 0 5px;
				}

				P {
					font-size: clamp(14px, .9vw, 18px);
				}
			}

			&_right {
				width: 70%;

				@media only screen and (max-width: 767px) {
					width: 100%;
				}

				@media only screen and (min-width: 768px) and (max-width: 1024px) {
					width: 100%;
				}

				&_bio {
					height: 300px;
					overflow: auto;
					padding-right: 50px;
					text-align: justify;
					margin-bottom: 15px;

					@media only screen and (max-width: 767px) {
						padding-right: 20px;
						height: 200px;
						text-align: left;
					}

					@media only screen and (min-width: 768px) and (max-width: 1024px) {
						height: 350px;
						padding-left: 40px;
						text-align: left;
					}

					p {
						margin: 10px 0;
					}
				}

				&_btn {
					display: flex;
					justify-content: flex-end;
					padding-right: 50px;

					@media only screen and (max-width: 767px) {
						padding-right: 20px;
					}

					button {
						text-align: center;
						color: $white;
						font-size: clamp(13px, 1vw, 18px);
						font-weight: 500;
						padding: 10px 20px;
						background: linear-gradient(to right, $secondary 50%, $primary 50%);
						background-size: 200% 100%;
						background-color: $primary;
						background-position: right bottom;
						border-radius: 4px;
						transition: all .3s ease-out;
						text-decoration: none;
						text-transform: uppercase;
						border: none;
						cursor: pointer;
				
						&:hover {
							color: $white;
							background-color: $secondary;
							background-position: left bottom;
						}
					}
				}
			}
		}
	}
}