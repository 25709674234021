@import "../../_globals/variables";

.project {
	&__inner {
		padding: 50px $padding 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media only screen and (max-width: 767px) {
			flex-direction: column-reverse;
		}

		@media only screen and (min-width: 768px) and (max-width: 1024px) {
			flex-direction: column-reverse;
		}

		&_left {
			width: 49%;
			margin-right: 20px;

			@media only screen and (max-width: 767px) {
				width: 100%;
			}

			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				width: 100%;
			}

			h2 {
				font-size: clamp(30px, 2vw, 45px);
				margin: 20px 0;
			}
		
			P {
				margin: 15px 0;	
				text-align: justify;	
			}
		}

		&_right {
			width: 49%;
			margin-left: 20px;

			@media only screen and (max-width: 767px) {
				width: 100%;
				margin-left: 0;
				margin-bottom: 30px;
			}

			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				width: 100%;
				margin-bottom: 30px;
			}

			&_card {
				border: .5px solid $grey;
				border-radius: $border-radius;
				padding: 30px 20px;
				display: grid;
				grid-template-columns: repeat(2, minmax(0, 1fr));
				grid-template-rows: 1fr;
				gap: 30px 20px;
				grid-template-areas:
					". .";
		
				@media only screen and (max-width: 767px) {
					padding: 30px 30px 30px 10px;
					grid-template-columns: repeat(2, minmax(0, 1fr));
					gap: 30px 0;
					grid-template-areas:
						". .";
				}
		
		
				@media only screen and (min-width: 768px) and (max-width: 1024px) {
					padding: 20px 50px 20px 0;
					grid-template-columns: repeat(2, minmax(0, 1fr));
					gap: 20px 0;
					grid-template-areas:
						". .";
				}

				h3 {
					font-size: clamp(16px, 1vw, 20px);
					margin-bottom: 5px;
					line-height: 1;

					@media only screen and (max-width: 767px) {
						font-size: clamp(13px, .8vw, 16px);
					}
				}

				p {
					font-size: clamp(14px, .9vw, 18px);

					@media only screen and (max-width: 767px) {
						font-size: clamp(13px, .8vw, 16px);
					}
				}

				img {
					width: 30px;
					height: 30px;
					object-fit: contain;
				}
			}
		}

		&_bottom {
			display: flex;
			padding-bottom: 80px;

			@media only screen and (max-width: 767px) {
				display: block;
			}

			&_inner {
				width: 49%;
				margin-right: 20px;

				@media only screen and (max-width: 767px) {
					width: 100%;
					margin-right: 0;
				}

				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					margin: 25px 0;
				}

				h2 {
					font-size: clamp(23px, 1.8vw, 26px);
				}

				p {
					text-align: justify;
					margin: 15px 0;
				}

				img {
					height: 350px;
					width: 100%;
					object-fit: cover;
					border-radius: $border-radius;

					@media only screen and (min-width: 768px) and (max-width: 1024px) {
						height: 250px;
					}
				}

				&:last-child {
					margin-right: 0;
					margin-left: 20px;

					@media only screen and (max-width: 767px) {
						margin-left: 0;
					}
				}
			}
		}
	}

	&__gallery {
		padding: 0 $padding 100px;

		small {
			margin-left: 20px;
		}

		h2 {
			margin: 10px 0 20px 20px;
		}
	}
}