@import "../../_globals/variables";

.stats {
	display: flex;
	align-items: center;

	@media only screen and (max-width: 767px) {
		flex-direction: column;
		text-align: center;
	}

	@media only screen and (min-width: 768px) and (max-width: 1024px) {
		flex-direction: column;
		text-align: center;	
	}

	img {
		width: 50px;
		height: 50px;
		object-fit: contain;
		margin-right: 20px;

		@media only screen and (max-width: 767px) {
			margin-right: 0;
			margin-bottom: 15px;
		}

		@media only screen and (min-width: 768px) and (max-width: 1024px) {
			margin-right: 0;
			margin-bottom: 15px;
		}
	}

	&__details {

		h3 {
			line-height: 1.2;
		}

		p {
			font-size: clamp(16px, 1vw, 18px);
		}
	}
}