@import "../../../_globals/variables";

.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	pointer-events: none;
}