@import "../../_globals/variables";

.project {
	height: 45vh;
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	display: flex;
	align-items: flex-end;
	padding: 20px;
	border-radius: $border-radius;

	@media only screen and (max-width: 767px) {
		height: 40vh;
	}

	@media only screen and (min-width: 768px) and (max-width: 1024px) {
		height: 30vh;
	}

	&__inner {
		background-color: $white;
		padding: 10px 20px;
		border-left: 5px solid $secondary;
		border-radius: $border-radius;
		width: 100%;

		a {
			color: $heading;
				
			&:hover {
				color: $heading;
			}
		}

		p {
			color: $secondary;
			font-size: clamp(12px, .7vw, 16px);
			margin-bottom: 5px;
			font-weight: 600 ;
			text-transform: uppercase;
		}

		h5 {
			font-size: clamp(16px, 1.2vw, 20px);
			font-weight: 700;
			line-height: 1.2;
		}
	}
}