/* colors */
$primary: #080D1C;
$secondary: #DAA520;
$accent: #080d1c;
$link: #DAA520;
$white: #FFF;
$heading: #000;
$text: #454545;
$grey: #454545;
$background: #eaedf3;
$light-background: #f7f7f7;


$heading-font: 'Montserrat', sans-serif; //'Smooch Sans', sans-serif
$body-font: 'Montserrat', sans-serif; //'Catamaran', sans-serif

$box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.10);
$border-radius: 6px;

/* Fluid Layout */
$padding: clamp(30px, 9vw, 250px);