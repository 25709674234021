@import "../../_globals/variables";

.subsidiary {
	margin-bottom: 30px;

	&__inner {
		position: relative;
		overflow: hidden;
		text-align: center;
		padding: 15px 30px 50px;
		background-color: $white;
		border-radius: $border-radius;

		&::before {
			position: absolute;
			content: '';
			left: 50%;
			top: -45px;
			width: 120px;
			height: 120px;
			border-radius: 50%;
			transform: translateX(-50%);
			background-color: $light-background;
			border: .5px solid $background;
		}

		img {
			width: 3.5vw;
			height: auto;
			object-fit: contain;
			position: absolute;
			top: 0;
			left: 42.5%;

			@media only screen and (max-width: 767px) {
				width: 10vw;
				left: 44%;
			}

			@media only screen and (min-width: 768px) and (max-width: 1024px) {
				width: 6vw;
				left: 42%;
			}
		}

		h3 {
			font-size: clamp(18px, 1.25vw, 22px);
			line-height: 1.1;
			margin-top: 85px;
			margin-bottom: 15px;

			a {
				color: $heading;
				
				&:hover {
					color: $link;
				}
			}
		}
		
		p {
			font-size: clamp(14px, 1vw, 18px);
			margin-bottom: 15px;
		}
	}
}