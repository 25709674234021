@import "../../_globals/variables";

.values {
	margin-bottom: 30px;
	position: relative;
	box-shadow: $box-shadow;

	&__inner {
		position: relative;
		overflow: hidden;
		padding: 50px;
		background-color: $white;
		border-radius: $border-radius;

		&::before {
			position: absolute;
			content: '';
			left: -75px;
			top: -60px;
			width: 120px;
			height: 120px;
			border-radius: 50%;
			background-color: $secondary;
			transition: all 300ms ease;
		}

		&_top {
			display: flex;
			align-items: center;
			justify-content: space-between;

			img {
				width: 50px;
				height: 50px;
				object-fit: contain;
			}
		}

		h3 {
			font-size: clamp(18px, 1.3vw, 22px);
			margin: 10px 0;
		}

		h1 {
			color: $background;
		}
		
		p {
			font-size: clamp(14px, 1vw, 18px);
			margin-bottom: 15px;
		}
	}
}