@import "../../../_globals/variables";

.photo {
	height: 90vh;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media only screen and (max-width: 767px) {
		height: 50vh;
	}

	@media only screen and (min-width: 768px) and (max-width: 1024px) {
		height: 50vh;
	}
}