body {
	font-family: $body-font;
	font-size: clamp(16px, 1vw, 20px);
	color: $text;
	line-height: 1.5;

	a {
		text-decoration: none;
		color: $link;

		&:hover {
			color: $secondary;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $heading-font;
	font-weight: 700;
	color: $heading;
	letter-spacing: 1px;
	line-height: .9;
}

h1 {
	font-size: clamp(40px, 4vw, 70px);
	line-height: 1.1;
}

h2 {
	font-size: clamp(30px, 2vw, 45px);
}

h3 {
	font-size: clamp(23px, 1.8vw, 26px);
}

h4 {
	font-size: clamp(20px, 1.65vw, 25px);
	font-weight: 600;
}

h5 {
	font-size: clamp(18px, 1.25vw, 22px);
	font-weight: 600;
}

ul {
	list-style-type: disc;
	padding-inline-start: 20px;
	margin: 20px 0;
}

article {
	p {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

small {
	color: $secondary;
	font-size: clamp(14px, .9vw, 18px);
	font-weight: 600;
	text-transform: uppercase;
}