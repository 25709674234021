@import "../../_globals/variables";

.header {
	min-height: 50px !important;
	align-items: center;
	justify-content: space-between;
	display: flex;
	flex-wrap: nowrap;
	width: 100%;

	img {
		margin-top: 10px;
	}
}