@import "./_globals/_variables.scss";
@import "./_globals/_typography.scss";

* {
    margin: 0;
    padding: 0;
}

/* WebKit/Blink Browsers */
::selection {
    background: $secondary;
    color: $white;
}

/* Gecko Browsers */
::-moz-selection {
    background: $secondary;
    color: $white;
}

.MuiToolbar-gutters {
    padding: 10px 0 !important;

    @media only screen and (max-width: 767px) {
        padding: 10px 0 !important;
    }
}

// active menu
.active {
    border-bottom: 3px solid transparent !important;
}